import React, { useEffect, useState } from "react";
import { 
  sampleLikeAPI, 
  sampleUnlikeAPI 
} from "../../api/sounds";
import { ToastContainer, toast } from "react-toastify";


const Toggle = (props) => {

  const [toggle,setToggle] = useState(false);


  useEffect(() => {

      setToggle(props.is_liked)

  },[props])

  return (
    <React.Fragment>
      {toggle ? (
        <>
          <div className="cursor-pointer"
          
          onClick={ async () => {
            
            setToggle(!toggle);

            toast.success(`${props.sample.filename} is removed from your likes`, { className:"bg-[#C4FF48] text-[#000]"});
            await sampleUnlikeAPI(props.sample.id);

          }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M15.8337 11.6667C17.0753 10.45 18.3337 8.99167 18.3337 7.08333C18.3337 5.86776 17.8508 4.70197 16.9912 3.84243C16.1317 2.98289 14.9659 2.5 13.7503 2.5C12.2837 2.5 11.2503 2.91667 10.0003 4.16667C8.75033 2.91667 7.71699 2.5 6.25033 2.5C5.03475 2.5 3.86896 2.98289 3.00942 3.84243C2.14988 4.70197 1.66699 5.86776 1.66699 7.08333C1.66699 9 2.91699 10.4583 4.16699 11.6667L10.0003 17.5L15.8337 11.6667Z"
                fill="#CECFDA"
              />
            </svg>
          </div>
        </>
      ) : (
        <>
          <div className="cursor-pointer" 
          
          onClick={async () => {

            setToggle(!toggle);

            toast.success(`${props.sample.filename} was added your likes`,{ className:"bg-[#C4FF48] text-[#000]"});
            await sampleLikeAPI(props.sample.id);

          }}
          
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M15.8337 11.6667C17.0753 10.45 18.3337 8.99167 18.3337 7.08333C18.3337 5.86776 17.8508 4.70197 16.9912 3.84243C16.1317 2.98289 14.9659 2.5 13.7503 2.5C12.2837 2.5 11.2503 2.91667 10.0003 4.16667C8.75033 2.91667 7.71699 2.5 6.25033 2.5C5.03475 2.5 3.86896 2.98289 3.00942 3.84243C2.14988 4.70197 1.66699 5.86776 1.66699 7.08333C1.66699 9 2.91699 10.4583 4.16699 11.6667L10.0003 17.5L15.8337 11.6667Z"
                stroke="#E6E6E6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </>
      )}
    <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
        icon={false}
        toastStyle={{ backgroundColor: "#3f3d3d", }}
      />
    </React.Fragment>
  );
};

export default Toggle;
