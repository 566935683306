import musicProducerIcon from "../../../assets/icons/userTypeIcons/musicProducerIcon.svg";
import singerIcon from "../../../assets/icons/userTypeIcons/singerIcon.svg";
import songwriterIcon from "../../../assets/icons/userTypeIcons/songwriterIcon.svg";
import masteringEngineerIcon from "../../../assets/icons/userTypeIcons/masteringEngineerIcon.svg";
import mixingEngineerIcon from "../../../assets/icons/userTypeIcons/mixingEngineerIcon.svg";
import composerIcon from "../../../assets/icons/userTypeIcons/composerIcon.svg";

import sampleImg from "./Ellipse 730.png";

export const userTypes = [
  {
    label: "Music Producer",
    value: "Music Producer",
    iconSrc: musicProducerIcon,
  },
  {
    label: "Artist",
    value: "Artist",
    iconSrc: singerIcon,
  },
  {
    label: "Songwriter",
    value: "Songwriter",
    iconSrc: songwriterIcon,
  },
  {
    label: "Mastering Engineer",
    value: "Mastering Engineer",
    iconSrc: masteringEngineerIcon,
  },
  {
    label: "Mixing Engineer",
    value: "Mixing Engineer",
    iconSrc: mixingEngineerIcon,
  },
  {
    label: "Composer/Musician",
    value: "Composer/Musician",
    iconSrc: composerIcon,
  },
];

export const musicGenres = [
  "Reggeation",
  "Pop",
  "R&B",
  "Hip-Hop",
  "Trap",
  "RKT",
  "Soul",
  "Country",
  "K-Pop",
  "Rock",
];

export const publishersArr = [
  "N/A",
  "Sony ATV Publishing",
  "Universal Music Publishing Group",
  "Warner Chappel Music",
  "BMG Rights Management",
  "Kabalt Music Group",
  "Concord Music Publishing",
];

export const conncetPeopleArr = [
  {
    id: "1",
    name: "Becky Hill",
    role: "Artist",
    thumbnaiSrc: sampleImg,
    followers: "23k",
  },
  {
    id: "2",
    name: "Becky Hill",
    role: "Artist",
    thumbnaiSrc: sampleImg,
    followers: "23k",
  },
  {
    id: "3",
    name: "Becky Hill",
    role: "Artist",
    thumbnaiSrc: sampleImg,
    followers: "23k",
  },
  {
    id: "4",
    name: "Becky Hill",
    role: "Artist",
    thumbnaiSrc: sampleImg,
    followers: "23k",
  },
  {
    id: "5",
    name: "Becky Hill",
    role: "Artist",
    thumbnaiSrc: sampleImg,
    followers: "23k",
  },
  {
    id: "6",
    name: "Becky Hill",
    role: "Artist",
    thumbnaiSrc: sampleImg,
    followers: "23k",
  },
  {
    id: "7",
    name: "Becky Hill",
    role: "Artist",
    thumbnaiSrc: sampleImg,
    followers: "23k",
  },
  {
    id: "8",
    name: "Becky Hill",
    role: "Artist",
    thumbnaiSrc: sampleImg,
    followers: "23k",
  },
];
