/*************************************************************************
 * @file breadcrumb.types.ts
 * @author Karla Zamora
 * @desc Defines action types for breadcrumbs (navigation header).
 * 
 * @copyright (c) 2024 MVSSIVE. All rights reserved.
 *************************************************************************/

export enum BreadcrumbActionTypes {
    SET_BREADCRUMBS = 'SET_BREADCRUMBS',
    ADD_BREADCRUMB = 'ADD_BREADCRUMB',
    CLEAR_BREADCRUMBS = 'CLEAR_BREADCRUMBS',
    POP_BREADCRUMB = 'POP_BREADCRUMB',
  }
  
  export default BreadcrumbActionTypes;