/*************************************************************************
 * @file downloaded-samples.tsx
 * @author End Quote
 * @desc Page component for displaying user's downloaded items.
 * 
 * @copyright (c) 2024 MVSSIVE. All rights reserved.
 *************************************************************************/

/* LOCAL IMPORTS */
import PlayerContainer from "components/player/player-container";

const MyDownloadsPage = () => {
  return(
    <PlayerContainer source="downloads"/>
  );
};

export default MyDownloadsPage;