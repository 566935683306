import React, { useState, useRef, useEffect } from 'react';

const TermsOfUse = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [maxHeight, setMaxHeight] = useState('100px');
    const contentRef = useRef(null);

    const toggleExpansion = () => setIsExpanded(!isExpanded);

    useEffect(() => {
        if (isExpanded) {
            setMaxHeight(contentRef.current.scrollHeight + 'px');
        } else {
            setMaxHeight('49px');
        }
    }, [isExpanded]);

    // Replace this string with the actual terms text
    const termsOfUseText = (
      <>
          <p>Audio Samples:</p>
          <p>
              <strong>Royalty Rates:</strong> A starting point for royalty negotiations will be 1% of the Net Master Recording Royalty
              and 20% of the Net Publisher's Share.
          </p>
          <p>
              <strong>"Major Label" Release Advance Fee:</strong> For releases distributed by a major record label (defined as a
              record company belonging to the "Big Three": Universal Music Group, Sony Music Entertainment, or
              Warner Music Group), a starting point for negotiation will be a $1,000 advance fee. This fee is separate
              from royalties and is not recoupable.
          </p>
          <p>
              <strong>Negotiation:</strong> Both royalty rates and advance fees are negotiable based on factors including, but not
limited to, the specific sample used, the prominence of the sample in the final track, the Licensee's track
record, and the anticipated commercial success of the release. Negotiation will be facilitated through
Split-Sheets generated by the <strong><em>MVSSIVE</em></strong> Platform & legal team. These Split-Sheets will detail the
agreed-upon royalty rates, advance fees (if applicable), and crediting requirements.

          </p>
          <br />
          
          <p>Original Vocal Top-Lines:</p>
          <p>
              <strong>Royalty Rates:</strong> The starting point for royalty negotiations will be 20% of the Net Master Recording
              Royalty and 40% of the Net Publisher's Share For the Vocal Performer/Artist.
          </p>
          <p>
              <strong>Recording Advance Fee:</strong> A starting point for negotiation will be a $1,500 Recording Advance Fee. This
fee is separate from royalties and is 50% recoupable against royalties. Negotiation will be based on
factors including, but not limited to, the popularity of the vocalist, the uniqueness of the vocal melody and
lyrics, and the anticipated commercial success of the release.
          </p>
          <p>
              <strong>Performer Credits & Main Artist Designation:</strong> The original vocal creator will receive "Performer" credit
on all platforms where the track is distributed. Additionally, unless otherwise agreed upon through a
signed Split-Sheet, the original vocal creator will be designated as the "Main Artist" on Spotify
          </p>


          <br />

          <p><strong>Vocal Covers:</strong></p>
          <p>
              <strong>Royalty Rates:</strong> The starting point for royalty negotiations will be 15% of the Net Master Recording
                Royalty and 30% of the Net Publisher's Share (if any). Negotiation will be based on factors including, but
                not limited to, the popularity of the original song being covered, the uniqueness of the vocal arrangement,
                and the anticipated commercial success of the release.

            </p>
            <p>
              <strong>Recording Advance Fee:</strong> A starting point for negotiation will be a $1,000 Recording Advance Fee. This
              fee is separate from royalties and is not recoupable. Negotiation will be based on factors including, but
              not limited to, the popularity of the DJ/producer creating the cover, and the anticipated commercial
              success of the release.
          </p>
      </>
  );

    return (
        <div className="bg-[#101010] h-full">
            <div className="mx-[20px] border border-x-0 border-y-[#222] py-[20px] px-[20px]">
                <p className="text-[12px] text-[#626262] pb-[12px] font-['Mona-Sans-M']">
                    Terms of Use
                </p>
                <div>
                    <div
                        ref={contentRef}
                        style={{
                            maxHeight: maxHeight,
                            overflow: 'hidden',
                            transition: 'max-height 0.3s ease',
                            color: '#3D3D3D',
                            fontSize: '10px', // Adjust the size value according to your needs
                        }}
                    >
                        <p>{termsOfUseText}</p>
                    </div>

                    <button
                        onClick={toggleExpansion}
                        className="text-[#173C5F] text-[12px] underline cursor-pointer mt-2"
                    >
                        {isExpanded ? 'View Less' : 'View More'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TermsOfUse;
