import sampleImg from "../sampleData/download.png";

export const Data = [
  {
    title: "sideEffect",
    imageUrl: "",
    singer: "helvik hug",
    date: "12 March 2021",
    p1: "producer",
    p2: "producer",
    p3: "producer",
  },
  {
    title: "sideEffect",
    imageUrl: "https://i.imgur.com/qQ65p2E.jpg",
    singer: "helvik hug",
    date: "12 March 2021",
    p1: "producer",
    p2: "producer",
    p3: "producer",
  },
  {
    title: "sideEffect",
    imageUrl: "https://i.imgur.com/qQ65p2E.jpg",
    singer: "helvik hug",
    date: "12 March 2021",
    p1: "producer",
    p2: "producer",
    p3: "producer",
  },
  {
    title: "sideEffect",
    imageUrl: "https://i.imgur.com/qQ65p2E.jpg",
    singer: "helvik hug",
    date: "12 March 2021",
    p1: "producer",
    p2: "producer",
    p3: "producer",
  },
];

export const musicTableData = [
  {
    id: 1,
    link: "",
    fileName: "stx_120_guitar_loop",
    album: "SoundBoyz",
    time: "0:35",
    key: "BMinor",
    bpm: "122",
    status: "available",
    considering: [
      { name: "User1", icon: sampleImg },
      { name: "User2", icon: sampleImg },
      { name: "User3", icon: sampleImg },
    ],
  },
  {
    id: 2,
    link: "",
    fileName: "stx_120_guitar_loop_2",
    album: "SoundBoyz",
    time: "0:35",
    key: "BMinor",
    bpm: "122",
    status: "pending",
    considering: [
      { name: "User4", icon: sampleImg },
      { name: "User5", icon: sampleImg },
    ],
  },
  {
    id: 3,
    link: "",
    fileName: "stx_120_guitar_loop",
    album: "SoundBoyz",
    time: "0:35",
    key: "BMinor",
    bpm: "122",
    status: "available",
    considering: [
      { name: "User1", icon: sampleImg },
      { name: "User2", icon: sampleImg },
      { name: "User3", icon: sampleImg },
    ],
  },
  {
    id: 4,
    link: "",
    fileName: "stx_120_guitar_loop_2",
    album: "SoundBoyz",
    time: "0:35",
    key: "BMinor",
    bpm: "122",
    status: "pending",
    considering: [
      { name: "User4", icon: sampleImg },
      { name: "User5", icon: sampleImg },
    ],
  },
  {
    id: 5,
    link: "",
    fileName: "stx_120_guitar_loop",
    album: "SoundBoyz",
    time: "0:35",
    key: "BMinor",
    bpm: "122",
    status: "available",
    considering: [
      { name: "User1", icon: sampleImg },
      { name: "User2", icon: sampleImg },
      { name: "User3", icon: sampleImg },
    ],
  },
  {
    id: 6,
    link: "",
    fileName: "stx_120_guitar_loop_2",
    album: "SoundBoyz",
    time: "0:35",
    key: "BMinor",
    bpm: "122",
    status: "pending",
    considering: [
      { name: "User4", icon: sampleImg },
      { name: "User5", icon: sampleImg },
    ],
  },
  {
    id: 7,
    link: "",
    fileName: "stx_120_guitar_loop",
    album: "SoundBoyz",
    time: "0:35",
    key: "BMinor",
    bpm: "122",
    status: "available",
    considering: [
      { name: "User1", icon: sampleImg },
      { name: "User2", icon: sampleImg },
      { name: "User3", icon: sampleImg },
    ],
  },
  {
    id: 8,
    link: "",
    fileName: "stx_120_guitar_loop_2",
    album: "SoundBoyz",
    time: "0:35",
    key: "BMinor",
    bpm: "122",
    status: "pending",
    considering: [
      { name: "User4", icon: sampleImg },
      { name: "User5", icon: sampleImg },
    ],
  },
  {
    id: 9,
    link: "",
    fileName: "stx_120_guitar_loop",
    album: "SoundBoyz",
    time: "0:35",
    key: "BMinor",
    bpm: "122",
    status: "available",
    considering: [
      { name: "User1", icon: sampleImg },
      { name: "User2", icon: sampleImg },
      { name: "User3", icon: sampleImg },
    ],
  },
  {
    id: 10,
    link: "",
    fileName: "stx_120_guitar_loop_2",
    album: "SoundBoyz",
    time: "0:35",
    key: "BMinor",
    bpm: "122",
    status: "pending",
    considering: [
      { name: "User4", icon: sampleImg },
      { name: "User5", icon: sampleImg },
    ],
  },
  {
    id: 11,
    link: "",
    fileName: "stx_120_guitar_loop",
    album: "SoundBoyz",
    time: "0:35",
    key: "BMinor",
    bpm: "122",
    status: "available",
    considering: [
      { name: "User1", icon: sampleImg },
      { name: "User2", icon: sampleImg },
      { name: "User3", icon: sampleImg },
    ],
  },
  {
    id: 12,
    link: "",
    fileName: "stx_120_guitar_loop_2",
    album: "SoundBoyz",
    time: "0:35",
    key: "BMinor",
    bpm: "122",
    status: "pending",
    considering: [
      { name: "User4", icon: sampleImg },
      { name: "User5", icon: sampleImg },
    ],
  },
];
