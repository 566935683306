export const rolesArr = [
  "Producer",
  "Songwriter",
  "Instrumentalist",
  "Artist",
  "DJ",
  "Mixing Engineer",
  "Mastering Engineer",
  "Composer",
];

export const genresArr = ["Reggaeton", "Hip-Hop", "Pop", "Dance / EDM", "Rock"];

export const publishersArr = [
  "Universal Music Publishing Group",
  "Warner Choppal Music",
  "BMG Rights Management",
  "Kobalt Music Group",
  "Concord Music Publishing",
  "Sony AtV Publishing",
];
