/*************************************************************************
 * @file sounds.types.ts
 * @author Zohaib Ahmed
 * @desc Defines action types for sounds-related actions.
 * 
 * @copyright (c) 2024 MVSSIVE. All rights reserved.
 *************************************************************************/

export enum SoundActionTypes {
  GET_SOUNDS = 'GET_SOUNDS',
  GET_SOUNDS_FAIL = 'GET_SOUNDS_FAIL',
  GET_SOUND_SAMPLES = 'GET_SOUND_SAMPLES',
  GET_SOUND_SAMPLES_FAIL = 'GET_SOUND_SAMPLES_FAIL',
}

export default SoundActionTypes;
